import * as React from "react";

import {
  useNotify,
  Datagrid,
  DateTimeInput,
  Create,
  List,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  TextField,
  DateField,
  FormDataConsumer,
  SimpleList,
} from "react-admin";
import { useMediaQuery } from "@mui/material";
import { Button } from "@mui/material";
import { GoogleMap } from "../components/GoogleMap";
import { Marker } from "../components/Marker";
import VoronoiMap from "../components/VoronoiMap";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import { Circle } from "@react-google-maps/api";

const huntsFilter = [
  <ReferenceInput
    label="Deelgebied"
    source="subarea_id"
    reference="subareas"
    alwaysOn
  >
    <SelectInput optionText="name" label="Deelgebied" />
  </ReferenceInput>,
];

export const HuntList = (props: any) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <List {...props} filters={huntsFilter} exporter={false}>
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.subarea.name}
          secondaryText={<DateField source="created" label="Tijd" showTime />}
          tertiaryText={(record) => record.hunt_code}
        />
      ) : (
        <Datagrid>
          <TextField source="subarea.name" label="Deelgebied" />
          <TextField source="rdx" />
          <TextField source="rdy" />
          <TextField source="latitude" />
          <TextField source="longitude" />
          <TextField source="address" />
          <TextField source="hunt_code" />
          <DateField source="created" label="Tijd" showTime />
        </Datagrid>
      )}
    </List>
  );
};

export const HuntCreate = (props: any) => {
  const notify = useNotify();

  const [location, setLocation] = React.useState({
    latitude: 52.0,
    longitude: 6.0,
    accuracy: 0,
  });

  function getCurrentPosition() {
    navigator.geolocation.getCurrentPosition(
      (value) => {
        setLocation({
          latitude: value.coords.latitude,
          longitude: value.coords.longitude,
          accuracy: value.coords.accuracy,
        });
      },
      (error) => {
        notify(`Kan locatie niet ophalen: ${error.message}`, { type: "error" });
      }
    );
  }

  const transform = (data: any) => ({
    ...data,
    latitude: location.latitude,
    longitude: location.longitude,
  });

  return (
    <Create {...props} transform={transform}>
      <SimpleForm>
        <TextInput label="Huntcode" source="hunt_code" />
        <ReferenceInput
          label="Deelgebied"
          source="subarea_id"
          reference="subareas"
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <DateTimeInput source="created" defaultValue={Date.now()} />
        <Button
          variant="contained"
          onClick={getCurrentPosition}
          startIcon={<GpsFixedIcon />}
        >
          <>Gebruik GPS locatie</>
        </Button>

        <TextField source="location" label="Locatie" />

        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <GoogleMap
              center={{ lat: location.latitude, lng: location.longitude }}
              containerStyle={{ height: "30vh" }}
              zoom={15}
            >
              <Marker
                type="hunt"
                position={{
                  latitude: location.latitude,
                  longitude: location.longitude,
                }}
                draggable={true}
                onDragEnd={(lat: any, lng: any) =>
                  setLocation({ latitude: lat, longitude: lng, accuracy: 0 })
                }
              ></Marker>
              {location.accuracy && (
                <Circle
                  center={{ lat: location.latitude, lng: location.longitude }}
                  radius={location.accuracy / 2}
                  options={{
                    strokeColor: "#00b0ff",
                    fillColor: "#1967d2",
                    zIndex: 12,
                    strokeWeight: 1,
                  }}
                />
              )}
              <VoronoiMap />
            </GoogleMap>
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};
