import { GoogleMap } from "../components/GoogleMap";
import VoronoiMap from "../components/VoronoiMap";

import { useMediaQuery } from "@mui/material";
import { useState } from "react";
import { GroupCircles } from "../components/GroupCircles";
import { Cars } from "../components/Cars";
import { Hints } from "../components/Hints";
import { Groups } from "../components/Groups";
import { SidebarContent } from "../components/SidebarContent";
import { FoxTeams } from "../components/FoxTeams";
import { SubareaStatus } from "../components/SubareaStatus";
import SpeedDialButtons from "../components/SpeedDialButtons";

export const Map = (props: any) => {
  const [filter, setFilter] = useState(0);
  const [sidebarContent, setSidebarContent] = useState(null);
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <>
      <SidebarContent
        sidebarContent={sidebarContent}
        setSidebarContent={setSidebarContent}
      />

      <SubareaStatus setFilter={setFilter} filter={filter} />

      <GoogleMap searchBox={!isSmall} setSidebar={setSidebarContent}>
        <Groups setSidebar={setSidebarContent} subareaFilter={filter} />

        <GroupCircles subareaFilter={filter} />

        <Cars setSidebar={setSidebarContent} />

        <Hints setSidebar={setSidebarContent} subareaFilter={filter} />

        <VoronoiMap subareaFilter={filter} />

        <FoxTeams setSidebar={setSidebarContent} subareaFilter={filter} />
      </GoogleMap>
      <SpeedDialButtons />
    </>
  );
};
