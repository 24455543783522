const PHI0 = 52.1551744;
const LAM0 = 5.38720621;
const X0 = 155000;
const Y0 = 463000;

// eslint-disable-next-line no-extend-native
Number.prototype.padFunction = function (padStr, len) {
  var num = this.toString();
  while (num.length < len) {
    num = num + padStr;
  }
  return parseInt(num);
};

export function rdtowgs(x, y) {
  y = parseInt(y).padFunction("0", 6);
  x = parseInt(x).padFunction("0", 6);
  var dX = (x - 155000) * Math.pow(10, -5);
  var dY = (y - 463000) * Math.pow(10, -5);

  var SomN =
    3235.65389 * dY +
    -32.58297 * Math.pow(dX, 2) +
    -0.2475 * Math.pow(dY, 2) +
    -0.84978 * Math.pow(dX, 2) * dY +
    -0.0655 * Math.pow(dY, 3) +
    -0.01709 * Math.pow(dX, 2) * Math.pow(dY, 2) +
    -0.00738 * dX +
    0.0053 * Math.pow(dX, 4) +
    -0.00039 * Math.pow(dX, 2) * Math.pow(dY, 3) +
    0.00033 * Math.pow(dX, 4) * dY +
    -0.00012 * dX * dY;

  var SomE =
    5260.52916 * dX +
    105.94684 * dX * dY +
    2.45656 * dX * Math.pow(dY, 2) +
    -0.81885 * Math.pow(dX, 3) +
    0.05594 * dX * Math.pow(dY, 3) +
    -0.05607 * Math.pow(dX, 3) * dY +
    0.01199 * dY +
    -0.00256 * Math.pow(dX, 3) * Math.pow(dY, 2) +
    0.00128 * dX * Math.pow(dY, 4) +
    0.00022 * Math.pow(dY, 2) +
    -0.00022 * Math.pow(dX, 2) +
    0.00026 * Math.pow(dX, 5);

  var latitude = 52.15517 + SomN / 3600;
  var longitude = 5.387206 + SomE / 3600;

  return [latitude, longitude];
}

export function wgstord(lat, lng) {
  const pqr = [
    [0, 1, 190094.945],
    [1, 1, -11832.228],
    [2, 1, -114.221],
    [0, 3, -32.391],
    [1, 0, -0.705],
    [3, 1, -2.34],
    [1, 3, -0.608],
    [0, 2, -0.008],
    [2, 3, 0.148],
  ];

  const pqs = [
    [1, 0, 309056.544],
    [0, 2, 3638.893],
    [2, 0, 73.077],
    [1, 2, -157.984],
    [3, 0, 59.788],
    [0, 1, 0.433],
    [2, 2, -6.439],
    [1, 1, -0.032],
    [0, 4, 0.092],
    [1, 4, -0.054],
  ];

  let dphi = 0.36 * (lat - PHI0);
  let dlam = 0.36 * (lng - LAM0);

  let X = X0;
  let Y = Y0;

  for (let i = 0; i < pqr.length; i++) {
    X += pqr[i][2] * Math.pow(dphi, pqr[i][0]) * Math.pow(dlam, pqr[i][1]);
  }

  for (let i = 0; i < pqs.length; i++) {
    Y += pqs[i][2] * Math.pow(dphi, pqs[i][0]) * Math.pow(dlam, pqs[i][1]);
  }

  return [Math.round(X), Math.round(Y)];
}
