import {
  Datagrid,
  List,
  TextField,
  TextInput,
  DateField,
  Show,
  SimpleShowLayout,
  RichTextField,
  SelectInput,
  SimpleList,
} from "react-admin";
import { useMediaQuery } from "@mui/material";
import { Breadcrumbs } from "../components/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

const postFilters = [
  <TextInput label="Zoeken" source="all_text" alwaysOn />,
  <SelectInput
    source="type"
    label="Categorie"
    choices={[
      { id: "hint", name: "Hint" },
      { id: "assignment", name: "Opdracht" },
      { id: "news", name: "Nieuws" },
    ]}
    alwaysOn
  />,
];

export const ArticleList = (props: any) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <List {...props} filters={postFilters} exporter={false}>
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.title}
          secondaryText={<DateField source="created" label="Tijd" showTime />}
          tertiaryText={(record) => record.type}
          linkType="show"
        />
      ) : (
        <Datagrid rowClick="show" bulkActionButtons={false}>
          <TextField source="title" />
          <TextField source="type" label="Category" />
          <TextField
            source="message_max_points"
            label="Maximaal aantal punten"
          />
          <DateField source="created" label="Tijd" showTime />
        </Datagrid>
      )}
    </List>
  );
};

export const ArticleShow = () => (
  <Show>
    <Breadcrumbs>
      <Link underline="hover" color="inherit" href="/articles">
        Nieuws
      </Link>
      <Typography color="text.primary">
        <TextField source="title" />
      </Typography>
    </Breadcrumbs>
    <SimpleShowLayout>
      <TextField source="title" />
      <DateField source="created" label="Tijd" showTime />
      <TextField source="message_max_points" label="Maximaal aantal punten" />
      <RichTextField source="message_content" label="Bericht" />
    </SimpleShowLayout>
  </Show>
);
