import MuiBreadcrumbs from "@mui/material/Breadcrumbs";
import MuiLink from "@mui/material/Link";
import React from "react";

interface PropTypes {
  children: React.ReactNode;
}

export const Breadcrumbs = (props: PropTypes) => {
  return (
    <MuiBreadcrumbs aria-label="breadcrumb">
      <MuiLink underline="hover" color="inherit" href="/">
        Home
      </MuiLink>
      {props.children}
    </MuiBreadcrumbs>
  );
};
