import { useGetList, useGetOne } from "react-admin";
import { Car, CarLocationHistory } from "../generated";
import { Marker } from "./Marker";
import { Typography, CardContent } from "@mui/material";
import DateTimeField from "./DateTimeField";
import { getHourFilter } from "../utils/dates";
import { useState } from "react";
import { Polyline } from "@react-google-maps/api";

interface Props {
  setSidebar: any;
}

export const Cars = (props: Props) => {
  const filter: any = getHourFilter();
  const [selectedCarId, setSelectedCarId] = useState<number | null>(null);

  const { data: cars } = useGetList<Car>("cars", {
    pagination: { page: 1, perPage: 100 },
    filter: filter,
  });

  const { data: carHistory } = useGetOne<CarLocationHistory>(
    "cars/history",
    { id: `${selectedCarId}` },
    { enabled: selectedCarId !== null }
  );

  const setSidebar = (children: any, carId: number) => {
    if (selectedCarId === carId) {
      setSelectedCarId(null);
    } else {
      setSelectedCarId(carId);
    }
    props.setSidebar(children);
  };

  return (
    <>
      {cars &&
        cars.map((car: Car, index: number) => (
          <Marker
            position={{ latitude: car.latitude, longitude: car.longitude }}
            type="car"
            key={"car-" + index}
            setSidebar={(children: any) => setSidebar(children, car.id)}
            onDragEnd={() => {}}
          >
            <CardContent>
              <Typography variant="h5" component="div">
                {car.name}
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                <DateTimeField source="created" record={car} />
              </Typography>
            </CardContent>
          </Marker>
        ))}
      {carHistory && selectedCarId && (
        <Polyline
          path={carHistory.locations.map(
            (loc: { latitude: number; longitude: number }) => ({
              lat: loc.latitude,
              lng: loc.longitude,
            })
          )}
          options={{
            strokeColor: "#00CED1",
            strokeOpacity: 1.0,
            strokeWeight: 2,
          }}
        />
      )}
    </>
  );
};
