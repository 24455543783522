import * as React from "react";
import { useMediaQuery } from "@mui/material";
import { Card, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  sidebarContent?: any;
  setSidebarContent: (content: any) => void;
}

export const SidebarContent = (props: Props) => {
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  return (
    <>
      {props.sidebarContent && isSmall ? (
        <Card
          sx={{
            position: "absolute",
            bottom: "0",
            left: "2rem",
            right: "2rem",
            maxHeight: "calc(100vh - 2rem)",
            width: "calc(100vw - 4rem)",
            zIndex: 100,
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              right: "0",
              top: "0",
              zIndex: 100,
            }}
            onClick={() => props.setSidebarContent(null)}
          >
            <CloseIcon />
          </IconButton>
          {props.sidebarContent}
        </Card>
      ) : (
        <Card
          sx={{
            position: "absolute",
            top: "calc(5rem + 48px)",
            right: "1rem",
            width: "30rem",
            zIndex: 100,
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              right: "0",
              top: "0",
              zIndex: 100,
            }}
            onClick={() => props.setSidebarContent(null)}
          >
            <CloseIcon />
          </IconButton>
          {props.sidebarContent}
        </Card>
      )}
    </>
  );
};
