import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  List,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import { ColorField, ColorInput } from "react-admin-color-picker";

export const SubareaList = (props: any) => (
  <List {...props} filters={[]}>
    <Datagrid>
      <TextField source="name" />
      <ColorField source="color" />
      <EditButton />
    </Datagrid>
  </List>
);

export const SubareaEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <ColorInput source="color" />
    </SimpleForm>
  </Edit>
);

export const SubareaCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <ColorInput source="color" />
    </SimpleForm>
  </Create>
);
