import { Link } from "react-admin";
import Button from "@mui/material/Button";

import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import { Notification } from "react-admin";
import authStyles from "./../components/authStyles";
import LockIcon from "@mui/icons-material/Lock";

const Activate = () => {
  return (
    <div style={authStyles.main}>
      <Card sx={authStyles.card}>
        <div style={authStyles.avatar}>
          <Avatar>
            <LockIcon />
          </Avatar>
        </div>
        <CardHeader title={`Registratie succesvol`} sx={authStyles.header} />
        <div style={authStyles.form}>
          Vraag iemand met een beheerdersaccount om je account te activeren.
        </div>
        <CardActions sx={authStyles.actions}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            to={"/login"}
            component={Link}
          >
            Inloggen
          </Button>
        </CardActions>
      </Card>
      <Notification />
    </div>
  );
};

export default Activate;
