import {
  BooleanField,
  BooleanInput,
  Datagrid,
  Edit,
  EditButton,
  EmailField,
  List,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

const filter = [<TextInput label="Zoeken" source="all_text" alwaysOn />];

export const UserList = (props: any) => (
  <List {...props} filter={filter}>
    <Datagrid>
      <EmailField source="email" label="E-mail" />
      <BooleanField source="is_active" label="Geactiveerd" />
      <BooleanField source="is_superuser" label="Administrator" />
      <EditButton />
    </Datagrid>
  </List>
);

export const UserEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="email" />
      <BooleanInput source="is_active" />
      <BooleanInput source="is_superuser" />
      <ReferenceInput label="Tenant" source="tenant_id" reference="tenants">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
