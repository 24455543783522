import { defaultTheme } from "react-admin";
import { indigo, yellow } from "@mui/material/colors";

export const lightTheme = {
  ...defaultTheme,
  palette: {
    mode: "light",
    primary: {
      light: indigo[500],
      main: indigo[800],
      dark: indigo[900],
      contrastText: "#fff",
    },
    secondary: {
      light: yellow[500],
      main: yellow[800],
      dark: yellow[900],
      contrastText: "#fff",
    },
  },
};

export const darkTheme = { ...defaultTheme, palette: { mode: "dark" } };
