import { Marker as GoogleMarker } from "@react-google-maps/api";

import carMarker from "../assets/marker/car-marker.png";
import hintMarker from "../assets/marker/hint-marker.png";
import huntMarker from "../assets/marker/hunt-marker.png";
import messageMarker from "../assets/marker/message-marker.png";
import groupMarker from "../assets/marker/group-marker.png";

interface Props {
  position: { latitude: number; longitude: number };
  type: "car" | "group" | "hint" | "hunt" | "message";
  draggable?: boolean;
  onDragEnd: (
    latitude: number | undefined,
    longitude: number | undefined
  ) => void;
  onClick?: () => void;
  setSidebar?: (children: any) => void;
  infoWindowOpen?: boolean;
  children?: any;
}

export const Marker = (props: Props) => {
  let icon = undefined;

  switch (props.type) {
    case "car":
      icon = carMarker;
      break;

    case "group":
      icon = groupMarker;
      break;

    case "hint":
      icon = hintMarker;
      break;

    case "hunt":
      icon = huntMarker;
      break;

    case "message":
      icon = messageMarker;
      break;

    default:
      icon = undefined;
      break;
  }

  const handleClick = () => {
    if (props.setSidebar) {
      props.setSidebar(props.children);
    }
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <GoogleMarker
      position={{ lat: props.position.latitude, lng: props.position.longitude }}
      draggable={props.draggable || false}
      onDragEnd={(e) => props.onDragEnd(e.latLng?.lat(), e.latLng?.lng())}
      onClick={handleClick}
      icon={icon}
    ></GoogleMarker>
  );
};
