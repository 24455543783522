import { useGetList, EditButton } from "react-admin";
import {
  Typography,
  CardActions,
  CardContent,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from "@mui/material";

import { Group } from "../generated";
import { Marker } from "./Marker";
import DirectionsIcon from "@mui/icons-material/Directions";
import CropIcon from "@mui/icons-material/Crop";

interface Props {
  setSidebar: any;
  subareaFilter: number | undefined;
}

export const Groups = (props: Props) => {
  const groups = useGetList("groups", {
    pagination: { page: 1, perPage: 100 },
    filter: props.subareaFilter
      ? { subarea_id: props.subareaFilter }
      : undefined,
  }).data;

  return (
    <>
      {groups &&
        groups.map((group: Group, index: number) => (
          <Marker
            key={"group-" + index}
            position={{ latitude: group.latitude, longitude: group.longitude }}
            type="group"
            setSidebar={props.setSidebar}
          >
            <>
              <CardContent>
                <Typography variant="h5" component="div">
                  {group.name}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  {group.accomodation}
                </Typography>
                <List>
                  <ListItem disablePadding>
                    <ListItemButton
                      href={
                        "https://www.google.com/maps/dir/?api=1&destination=" +
                        group.latitude +
                        "%2C" +
                        group.longitude
                      }
                      target="_blank"
                    >
                      <ListItemIcon>
                        <DirectionsIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <>
                            {group.street} {group.housenumber}
                            {group.housenumber_addition}, {group.postcode}{" "}
                            {group.city}
                          </>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                  {group.subarea && (
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <CropIcon />
                        </ListItemIcon>
                        <ListItemText primary={group.subarea.name} />
                      </ListItemButton>
                    </ListItem>
                  )}
                </List>
              </CardContent>
              <CardActions>
                <EditButton resource="groups" record={group} label="Bewerk" />
              </CardActions>
            </>
          </Marker>
        ))}
    </>
  );
};
