import { useGetList } from "react-admin";
import { Loading } from "react-admin";

export const LatestHint = () => {
  const { data, isLoading, error } = useGetList("articles", {
    pagination: { page: 1, perPage: 1 },
    sort: { field: "id", order: "DESC" },
    filter: { message_type: "hint" },
  });
  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  return (
    <div>
      {data.map((record) => (
        <div>
          <h4>{record.title}</h4>
          <div
            dangerouslySetInnerHTML={{ __html: record.message_content }}
          ></div>
        </div>
      ))}
    </div>
  );
};
