import {
  EditButton,
  Datagrid,
  Edit,
  List,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  NumberInput,
  WrapperField,
  SimpleList,
} from "react-admin";
import { useMediaQuery } from "@mui/material";
import { Breadcrumbs } from "../components/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

const filters = [
  <TextInput label="Zoeken" source="all_text" alwaysOn />,
  <ReferenceInput
    label="Deelgebied"
    source="subarea_id"
    reference="subareas"
    alwaysOn
  >
    <SelectInput optionText="name" label="Deelgebied" />
  </ReferenceInput>,
];

export const GroupList = (props: any) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <List {...props} filters={filters} sortby="name" actions={null}>
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.name}
          secondaryText={(record) =>
            `${record.street} ${record.housenumber}${
              record.housenumber_addition ? record.housenumber_addition : ""
            }, ${record.city}`
          }
          tertiaryText={(record) => record.subarea.name}
        />
      ) : (
        <Datagrid bulkActionButtons={false}>
          <TextField source="name" sortby="name" />
          <WrapperField label="Adres">
            <TextField source="street" />
            &nbsp;
            <TextField source="housenumber" />
            <TextField source="housenumber_addition" />
          </WrapperField>
          <TextField source="city" />
          <EditButton label="Deelgebied" />
        </Datagrid>
      )}
    </List>
  );
};

export const GroupEdit = (props: any) => (
  <Edit {...props}>
    <Breadcrumbs>
      <Link underline="hover" color="inherit" href="/groups">
        Groepen
      </Link>
      <Typography color="text.primary">Bewerken</Typography>
    </Breadcrumbs>
    <SimpleForm>
      <TextInput source="name" label="Naam" disabled />
      <ReferenceInput
        label="Deelgebied"
        source="subarea_id"
        reference="subareas"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <NumberInput source="latitude" label="Latitude" disabled />
      <NumberInput source="longitude" label="Longitude" disabled />
    </SimpleForm>
  </Edit>
);
