import { useState } from "react";
import { StandaloneSearchBox } from "@react-google-maps/api";
import {
  useNotify,
  Create,
  Datagrid,
  NumberField,
  DateTimeInput,
  List,
  ReferenceInput,
  SelectInput,
  DateField,
  SimpleList,
  TabbedForm,
  useCreate,
  useRefresh,
  useRedirect,
  TextField,
} from "react-admin";
import { useMediaQuery } from "@mui/material";
import { GoogleMap } from "../components/GoogleMap";
import { Marker } from "../components/Marker";
import { rdtowgs, wgstord } from "../utils/rd";
import { LatestHint } from "../components/LatestHint";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { TextField as MuiTextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import { Button } from "@mui/material";
import { HintCreate as HintCreateInterface } from "../generated";

const hintsFilter = [
  <ReferenceInput
    label="Deelgebied"
    source="subarea_id"
    reference="subareas"
    alwaysOn
  >
    <SelectInput optionText="name" label="Deelgebied" />
  </ReferenceInput>,
];

export const HintList = (props: any) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <List {...props} filters={hintsFilter} exporter={false}>
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.subarea.name}
          secondaryText={<DateField source="created" label="Tijd" showTime />}
        />
      ) : (
        <Datagrid>
          <TextField source="subarea.name" label="Deelgebied" />
          <TextField source="type" />
          <NumberField source="rdx" />
          <NumberField source="rdy" />
          <TextField source="address" />
          <DateField source="created" label="Tijd" showTime />
        </Datagrid>
      )}
    </List>
  );
};

export const HintCreate = (props: any) => {
  const notify = useNotify();
  const [create] = useCreate();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const [rd, setRd] = useState({ rdx: 0, rdy: 0 });
  const [wgs, setWgs] = useState({ latitude: 52.1998772, longitude: 6.21503 });
  const [submittableCoordinate, setSubmittableCoordinate] = useState({
    latitude: 52.1998772,
    longitude: 6.21503,
  });
  const [searchBox, setSearchBox] = useState<any>(null);
  const [formattedAddress, setFormattedAddress] = useState("");

  const wholeHour = new Date();
  wholeHour.setMinutes(0);
  wholeHour.setSeconds(0);

  const handleRdxChange = (e: any) => {
    const newValue = parseInt(e.target.value);
    setRd((prev) => ({ ...prev, rdx: newValue }));

    if (rd.rdy) {
      const [latitude, longitude] = rdtowgs(newValue, rd.rdy);
      setSubmittableCoordinate({ latitude, longitude });
    }
  };

  const handleRdyChange = (e: any) => {
    const newValue = parseInt(e.target.value);
    setRd((prev) => ({ ...prev, rdy: newValue }));

    if (rd.rdx) {
      const [latitude, longitude] = rdtowgs(rd.rdx, newValue);
      setSubmittableCoordinate({ latitude, longitude });
    }
  };

  const handleWGSLatChange = (e: any) => {
    const newValue = parseFloat(e.target.value);
    setWgs((prev) => ({ ...prev, latitude: newValue }));
    const [rdx, rdy] = wgstord(newValue, wgs.longitude);
    setRd({ rdx, rdy });
    setSubmittableCoordinate(wgs);
  };

  const handleWGSLngChange = (e: any) => {
    const newValue = parseFloat(e.target.value);
    setWgs((prev) => ({ ...prev, longitude: newValue }));
    const [rdx, rdy] = wgstord(wgs.latitude, newValue);
    setRd({ rdx, rdy });
    setSubmittableCoordinate(wgs);
  };

  const setLocation = ({ latitude, longitude }) => {
    setWgs({ latitude, longitude });
    const [rdx, rdy] = wgstord(latitude, longitude);
    setRd({ rdx, rdy });
    setSubmittableCoordinate({ latitude, longitude });
  };

  function getCurrentPosition() {
    navigator.geolocation.getCurrentPosition(
      (value) => {
        setLocation({
          latitude: value.coords.latitude,
          longitude: value.coords.longitude,
        });
      },
      (error) => {
        notify(`Kan locatie niet ophalen: ${error.message}`, { type: "error" });
      }
    );
  }

  const saveHint = (values: any) => {
    const data: HintCreateInterface = {
      latitude: submittableCoordinate.latitude,
      longitude: submittableCoordinate.longitude,
      rdx: rd.rdx,
      rdy: rd.rdy,
      subarea_id: values.subarea_id,
      created: values.created,
    };

    create(
      "hints",
      { data },
      {
        onSuccess: () => {
          notify(`Hint aangemaakt`);
          redirect("/hints");
          refresh();
        },
        onFailure: ({ error }) => {
          notify(`Fout: ${error.message}`, { type: "error" });
        },
      }
    );
  };

  const onSBLoad = (ref: any) => {
    setSearchBox(ref);
  };

  const onPlacesChanged = () => {
    if (searchBox) {
      const places = searchBox.getPlaces();

      if (places.length === 0) {
        notify(`Geen plaatsen gevonden`, { type: "warning" });
        return;
      }
      const place = places[0];
      const { lat, lng } = place.geometry.location;
      setLocation({ latitude: lat(), longitude: lng() });
      setFormattedAddress(place.formatted_address);
    }
  };

  return (
    <Create {...props}>
      <Breadcrumbs>
        <Link underline="hover" color="inherit" href="/hints">
          Hints
        </Link>
        <Typography color="text.primary">Toevoegen</Typography>
      </Breadcrumbs>

      <TabbedForm onSubmit={saveHint}>
        <TabbedForm.Tab label="Rijksdriehoek">
          <MuiTextField
            label="Rijksdriehoek X"
            type="number"
            value={rd.rdx}
            onChange={(e) => handleRdxChange(e)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <MuiTextField
            label="Rijksdriehoek Y"
            type="number"
            value={rd.rdy}
            onChange={(e) => handleRdyChange(e)}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <ReferenceInput
            label="Deelgebied"
            source="subarea_id"
            reference="subareas"
          >
            <SelectInput optionText="name" label="Deelgebied" required />
          </ReferenceInput>

          <DateTimeInput
            source="created"
            label="Tijdstip"
            defaultValue={wholeHour}
          />
        </TabbedForm.Tab>
        <TabbedForm.Tab label="WGS-84/GPS">
          <Button
            variant="contained"
            onClick={getCurrentPosition}
            startIcon={<GpsFixedIcon />}
          >
            <>Gebruik GPS locatie</>
          </Button>

          <MuiTextField
            label="Latitude"
            type="number"
            value={wgs.latitude}
            onChange={(e) => handleWGSLatChange(e)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <MuiTextField
            label="Longitude"
            type="number"
            value={wgs.longitude}
            onChange={(e) => handleWGSLngChange(e)}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <ReferenceInput
            label="Deelgebied"
            source="subarea_id"
            reference="subareas"
          >
            <SelectInput optionText="name" label="Deelgebied" required />
          </ReferenceInput>

          <DateTimeInput
            source="created"
            label="Tijdstip"
            defaultValue={wholeHour}
          />
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Zoeken">
          <StandaloneSearchBox
            onPlacesChanged={onPlacesChanged}
            onLoad={onSBLoad}
          >
            <MuiTextField
              style={{ width: "80vw" }}
              type="text"
              placeholder="Zoeken"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth={true}
            />
          </StandaloneSearchBox>

          <ReferenceInput
            label="Deelgebied"
            source="subarea_id"
            reference="subareas"
          >
            <SelectInput optionText="name" label="Deelgebied" required />
          </ReferenceInput>

          <DateTimeInput
            source="created"
            label="Tijdstip"
            defaultValue={wholeHour}
          />

          {formattedAddress && (
            <Typography>Getoonde locatie: {formattedAddress}</Typography>
          )}
        </TabbedForm.Tab>
      </TabbedForm>

      <GoogleMap
        center={{
          lat: submittableCoordinate.latitude,
          lng: submittableCoordinate.longitude,
        }}
        containerStyle={{ height: "30vh" }}
      >
        <Marker
          position={{
            latitude: submittableCoordinate.latitude,
            longitude: submittableCoordinate.longitude,
          }}
          draggable={true}
          onDragEnd={(lat: any, lng: any) =>
            setLocation({ latitude: lat, longitude: lng })
          }
        ></Marker>
      </GoogleMap>

      <LatestHint />
    </Create>
  );
};
