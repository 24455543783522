interface DateFilter {
  created: {
    $gte: Date;
    $lte: Date;
  };
}

/**
 *  A filter object that can be used in a query to filter on today's date.
 *
 * @returns {TodayFilter} A filter object that can be used in a query to filter on today's date.
 */
export function getTodayFilter(): DateFilter {
  const today_start = new Date();
  today_start.setHours(0, 0, 0, 0);
  const today_end = new Date();
  today_end.setHours(23, 59, 59, 999);

  return { created: { $gte: today_start, $lte: today_end } };
}

/**
 *  A filter object that can be used in a query to filter on events in the last hour.
 *
 * @returns {TodayFilter} A filter object.
 */
export function getHourFilter(): DateFilter {
  const hour_minus_one = new Date();
  hour_minus_one.setHours(hour_minus_one.getHours() - 1);
  hour_minus_one.setSeconds(0, 0);

  const hour_plus_one = new Date();
  hour_plus_one.setHours(hour_plus_one.getHours() + 1);
  hour_plus_one.setSeconds(0, 0);

  return { created: { $gte: hour_minus_one, $lte: hour_plus_one } };
}
